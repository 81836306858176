@import "whiteglass";

.featured_image {
    background: no-repeat center center;
    background-size: cover;
    height: 65vh;
    min-height: 180px;
    margin-bottom: 5vh;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    margin-top: -30px;
    
    @include media-query(900px) {
        height:45vh;
    }
    
    @include media-query(500px) {
        height:30vh;
    }
}

.post-content img {
    display: block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
